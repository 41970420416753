var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-wrap" },
    [
      _c("Header", {
        attrs: { mode: 2, searchKey: _vm.searchKey, cityCode: _vm.cityCode },
        on: { search: _vm.doSearch },
      }),
      _c(
        "div",
        { staticClass: "main-wrap" },
        [
          _c(
            "MapListTemplate",
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.isLoading,
                      expression: "isLoading",
                    },
                  ],
                  staticClass: "left-panel no-scrollbar",
                  class: { "no-pager": _vm.isHidePager },
                  attrs: { slot: "leftPanel" },
                  slot: "leftPanel",
                },
                [
                  _c("div", { staticClass: "left-navs" }, [
                    _c("ul", [
                      _c(
                        "li",
                        { on: { click: _vm.hideCollectList } },
                        [
                          _c(
                            "FilterBtn",
                            {
                              attrs: {
                                data: _vm.filterMenusData,
                                filterData: _vm.curFilterData,
                                isSelectClose: true,
                              },
                              on: { selectFilter: _vm.selectFilter },
                            },
                            [
                              _c("Icon", { attrs: { custom: "icon-filter" } }),
                              _vm._v(" 高级筛选 "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        {
                          class: { active: _vm.isShowCollection },
                          on: { click: _vm.changeCollectShow },
                        },
                        [
                          _c("Icon", { attrs: { custom: "icon-favorite" } }),
                          _vm._v(" 我的收藏 "),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "left-list-content",
                      class: { "g-login-padding": !_vm.isLogin() },
                    },
                    [
                      _c(
                        "ul",
                        [
                          _vm._l(_vm.showList, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: "show_" + index,
                                ref: `brandListItem${index}`,
                                refInFor: true,
                                class: { cur: _vm.curBrandId === item.code },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSelectBrand(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pic cur-pointer",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.gotoCityDetail(item.code)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: item.logo || _vm.normalPic,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                                _c("div", { staticClass: "list-cont" }, [
                                  _c("div", [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    !item.isCollect
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "collect",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.addCollect(item.code)
                                              },
                                            },
                                          },
                                          [_vm._v("收藏")]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "collect no-collect",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.delCollect(item.code)
                                              },
                                            },
                                          },
                                          [_vm._v("取消收藏")]
                                        ),
                                  ]),
                                  _c("div", { staticClass: "list-item" }, [
                                    _vm._v(" 城市级别: "),
                                    _c("a", [_vm._v(_vm._s(item.cityLevel))]),
                                  ]),
                                  _c("div", { staticClass: "list-item" }, [
                                    _vm._v(" 城市线级: "),
                                    _c("a", [
                                      _vm._v(_vm._s(item.cityLineLevel)),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "link hover-show",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.gotoCityDetail(item.code)
                                          },
                                        },
                                      },
                                      [_vm._v("查看详情")]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "cur-box" }),
                                _c("img", {
                                  staticClass: "triangle-icon",
                                  attrs: {
                                    src: require("../../assets/images/icon/triangle.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          }),
                          !_vm.isLogin()
                            ? [
                                _c("li", { staticClass: "visitor-login" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "visitor-login-btn",
                                      attrs: { role: "button" },
                                      on: { click: _vm.gotoLogin },
                                    },
                                    [
                                      _vm._v(" 登录查看全部信息"),
                                      _c("img", {
                                        staticClass: "visitor-login-icon",
                                        attrs: {
                                          src: require("@/assets/images/double-arror.png"),
                                          width: "14",
                                          height: "14",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      !_vm.isLoading && _vm.showList.length === 0
                        ? _c(
                            "div",
                            { staticClass: "no-data" },
                            [
                              _c("no-data", {
                                staticClass: "center-middle-box",
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c("PagerLittle", {
                    staticClass: "left-list-pager",
                    attrs: {
                      total: _vm.total,
                      page: _vm.page,
                      pageSize: _vm.pageSize,
                    },
                    on: { "on-change": _vm.handlePageChange },
                  }),
                ],
                1
              ),
              _c("MapPanel", {
                ref: "mapPanel",
                attrs: { isSelectCity: true, ignoreFitChina: true },
                on: {
                  loaded: _vm.handleMapLoaded,
                  click: _vm.handleMapClick,
                  selectCity: _vm.handleSelectCity,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }