<template>
  <div class="site-wrap">
    <Header
      :mode="2"
      :searchKey="searchKey"
      :cityCode="cityCode"
      @search="doSearch"
    ></Header>
    <div class="main-wrap">
      <MapListTemplate>
        <div
          class="left-panel no-scrollbar"
          :class="{ 'no-pager': isHidePager }"
          slot="leftPanel"
          v-loading="isLoading"
        >
          <div class="left-navs">
            <ul>
              <li @click="hideCollectList">
                <FilterBtn
                  :data="filterMenusData"
                  :filterData="curFilterData"
                  :isSelectClose="true"
                  @selectFilter="selectFilter"
                >
                  <Icon custom="icon-filter"></Icon>
                  高级筛选
                </FilterBtn>
              </li>
              <li
                :class="{ active: isShowCollection }"
                @click="changeCollectShow"
              >
                <Icon custom="icon-favorite"></Icon>
                我的收藏
              </li>
            </ul>
          </div>
          <div
            class="left-list-content"
            :class="{ 'g-login-padding': !isLogin() }"
          >
            <ul>
              <li
                v-for="(item, index) in showList"
                :key="'show_' + index"
                :class="{ cur: curBrandId === item.code }"
                @click="handleSelectBrand(item)"
                :ref="`brandListItem${index}`"
              >
                <div
                  class="pic cur-pointer"
                  @click.stop="gotoCityDetail(item.code)"
                >
                  <img :src="item.logo || normalPic" alt="" />
                </div>
                <div class="list-cont">
                  <div>
                    <div class="title">{{ item.name }}</div>
                    <span
                      class="collect"
                      v-if="!item.isCollect"
                      @click.stop="addCollect(item.code)"
                      >收藏</span
                    >
                    <span
                      class="collect no-collect"
                      @click.stop="delCollect(item.code)"
                      v-else
                      >取消收藏</span
                    >
                  </div>
                  <div class="list-item">
                    城市级别: <a>{{ item.cityLevel }}</a>
                  </div>
                  <div class="list-item">
                    城市线级: <a>{{ item.cityLineLevel }}</a>
                    <span
                      class="link hover-show"
                      @click.stop="gotoCityDetail(item.code)"
                      >查看详情</span
                    >
                  </div>
                </div>
                <div class="cur-box"></div>
                <img
                  class="triangle-icon"
                  src="../../assets/images/icon/triangle.png"
                  alt=""
                />
              </li>
              <template v-if="!isLogin()">
                <li class="visitor-login">
                  <div
                    role="button"
                    class="visitor-login-btn"
                    @click="gotoLogin"
                  >
                    登录查看全部信息<img
                      class="visitor-login-icon"
                      :src="require('@/assets/images/double-arror.png')"
                      width="14"
                      height="14"
                    />
                  </div>
                </li>
              </template>
            </ul>
            <div class="no-data" v-if="!isLoading && showList.length === 0">
              <no-data class="center-middle-box"></no-data>
            </div>
            <!-- <Spin fix v-if="isLoading"></Spin> -->
          </div>
          <PagerLittle
            class="left-list-pager"
            :total="total"
            :page="page"
            :pageSize="pageSize"
            @on-change="handlePageChange"
          ></PagerLittle>
        </div>
        <MapPanel
          ref="mapPanel"
          :isSelectCity="true"
          :ignoreFitChina="true"
          @loaded="handleMapLoaded"
          @click="handleMapClick"
          @selectCity="handleSelectCity"
        ></MapPanel>
      </MapListTemplate>
    </div>
  </div>
</template>

<script>
import FilterBtn from "@/components/filter/FilterBtn.vue";
import MapPanel from "@/components/MapPanel.vue";
import PagerLittle from "@/components/PagerLittle";
import normalPic from "@/assets/images/normal_city.png";
import { cityFilters } from "js/cityData.js";
import InfoWindowBrand from "@/components/InfoWindowBrand.vue";
import Vue from "vue";
import { SearchData, GetCommonInfo, GetDetailBrowsingRecord } from "@/service";
import collectMixin from "@/mixins/collect.js";
import { openNewWindow, formatFieldsItem, login, isLogin } from "js/utils.js";

export default {
  mixins: [collectMixin],
  metaInfo: {
    title: "查城市",
    meta: [
      {
        name: "keywords",
        content:
          "千里目,全国城市,城市数据,城市排名,位置分布,城市地图,城市级别,城市等级,城市线级划分,对比分析",
      },
      {
        name: "description",
        content:
          "千里目覆盖全国城市数据，具备精准搜索、通过地图快速定位、对比分析等功能，支持查看全国城市位置分布、城市地图、城市级别、城市所属线级等详细信息。",
      },
    ],
  },
  data() {
    return {
      isLoading: true,
      mapPanel: null,
      isLoadedMap: false,
      curFilterData: {},
      searchKey: "",
      cityCode: "",
      page: 1,
      pageSize: 20,
      total: 0,
      cityList: [],
      normalPic: normalPic,
      collectList: [], // 收藏列表
      isShowCollection: false, // 显示收藏列表

      curBrandId: "",
    };
  },
  computed: {
    sort() {
      return this.$store.getters.getSortId("city");
    },
    filterMenusData() {
      return {
        title: "筛选",
        filters: cityFilters,
      };
    },
    showList() {
      if (this.isShowCollection) {
        return this.collectList;
      }
      return this.cityList;
    },
    isHidePager() {
      return this.isShowCollection || this.total < this.pageSize;
    },
  },
  methods: {
    async initData() {
      for (let i = 0, n = cityFilters.length; i < n; i++) {
        let item = cityFilters[i];
        this.curFilterData[item.key] = [];
        this.$set(this.curFilterData, item.key, this.curFilterData[item.key]);
      }

      await this.mapPanel.waitLoaded();
      this.initTable();
    },
    isLogin,
    gotoLogin: login,
    async initTable() {
      this.isLoading = true;
      let filters = [];
      let cityType = "";
      for (let key in this.curFilterData) {
        let val = this.curFilterData[key];
        if (!val || val.length === 0) {
          continue;
        }
        if (key === "cityType") {
          cityType = val[0];
          continue;
        }
        filters.push({
          field: key,
          condition: val,
        });
      }
      if (this.cityCode) {
        // filters.push({
        //   field: "cityCode",
        //   condition: [this.cityCode],
        // });
      }
      let params = {
        searchSort: this.sort,
        word: this.searchKey,
        page: this.page || 1,
        pageSize: this.pageSize || 10,
        filterCondition: filters,
        orderCondition: {
          field: "",
          condition: "desc",
        },
        lat: "",
        lon: "",
      };
      if (cityType && cityType !== "0") {
        params.cityType = parseInt(cityType);
      }
      let res = await SearchData(params);
      if (res && res.code === 200) {
        let list = res.data.items || [];
        // 查询收藏状态
        list = await this.checkListCollectStatus(list);
        this.cityList = list;
        this.total = res.data.pages.total;
      }
      this.showPoints(this.cityList);
      this.isLoading = false;
    },
    doSearch(searchKey) {
      this.searchKey = searchKey;
      this.page = 1;
      this.initTable();
    },
    selectFilter(value) {
      let obj = this.curFilterData || {};
      for (let key in value) {
        obj[key] = value[key];
      }
      this.curFilterData = Object.assign({}, this.curFilterData, obj);
      this.page = 1;
      this.initTable();
    },
    //翻页
    handlePageChange(page) {
      this.page = page;
      this.initTable();
    },
    handleMapLoaded() {
      this.isLoadedMap = true;
    },
    handleMapClick(feature) {
      if (!feature) {
        return;
      }
      this.curBrandId = feature.properties.code || "";
      let divHeight = this.$refs.brandListItem0[0].offsetHeight;
      let topDistance = 0;
      //console.log(this.showList,999)
      for (let i in this.showList) {
        if (this.showList[i].code === feature.properties.code) {
          topDistance = Number(i) * divHeight;
        }
      }
      this.scrollToBottom(topDistance);
      this.openWindowInfo(feature);
    },
    //列表滚动到某个位置
    scrollToBottom(topDistance) {
      this.$nextTick(() => {
        let list = this.$el.querySelector(".left-list-content");
        list.scrollTop = topDistance; //滚动到距离元素顶部 40px
      });
    },
    openWindowInfo(feature) {
      let properties = feature.properties;
      let center = feature.geometry.coordinates;
      let InfoWindow = Vue.extend(InfoWindowBrand);
      let instance = new InfoWindow({
        propsData: {
          title: properties["name"],
          list: [
            `城市级别: ${properties["cityLevel"]}`,
            `城市线级: ${properties["cityLineLevel"]}`,
          ],
          btnText: "查看详情",
        },
      });
      instance.$on("doSubmit", () => {
        let code = properties.code;
        this.gotoCityDetail(code);
      });
      instance.$mount();
      let content = instance.$el;
      let that = this;
      that.mapPanel.openInfoWindow(center, content, {
        offset: [0, -23],
        autoMove: false,
        close() {
          that.curBrandId = "";
        },
      });
    },
    showPoints(list) {
      let features = [];
      for (let i = 0, n = list.length; i < n; i++) {
        let item = list[i];
        let feature = this.getFeatureByItem(item);
        if (!feature) {
          continue;
        }
        features.push(feature);
      }
      let options = {
        icon: "/images/marker_blue2.png",
        iconSize: [22, 33],
        anchor: "bottom-center",
      };
      this.mapPanel.setMarkers("markers", features, options);
      this.mapPanel.fitBounds(features);
    },
    getFeatureByItem(item) {
      let geom = item.geom;
      if (!geom) {
        return null;
      }
      if (typeof geom === "string") {
        geom = JSON.parse(geom);
      }
      let feature = {
        type: "Feature",
        geometry: geom,
        properties: item,
      };
      return feature;
    },

    // 选择左侧列表
    handleSelectBrand(item) {
      if (this.curBrandId === item.code) {
        return;
      }
      this.curBrandId = item.code;
      this.mapPanel.changeCityName(item.name);
      this.openLeftItem(item);
    },
    openLeftItem(item) {
      let feature = this.getFeatureByItem(item);
      if (!item) {
        return;
      }
      this.openWindowInfo(feature);
      this.mapPanel.fitPointBounds(feature, { immediately: true });
    },
    async handleSelectCity(city) {
      this.curBrandId = ""; //因分页原因，无法定位到左侧列表的相应城市，所以高亮取消
      let adcode = city.adcode;
      this.cityCode = adcode;
      for (let i = 0, n = this.cityList.length; i < n; i++) {
        let item = this.cityList[i];
        if (item.code === adcode) {
          this.openLeftItem(item);
          return;
        }
      }

      let fields = [
        "城市名称",
        "城市级别",
        "城市线级",
        "城市默认点位经度",
        "城市默认点位纬度",
      ];
      let params = {
        identify: "sg-data-城市-城市详情信息",
        filters: [
          {
            field: "城市编码",
            cond: 0,
            value: [adcode],
          },
        ],
        fields: fields,
        pages: {
          page: 1,
          size: 10,
        },
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let dataItem = formatFieldsItem(res.data);
        if (dataItem) {
          let center = [
            dataItem["城市默认点位经度"],
            dataItem["城市默认点位纬度"],
          ];
          let geometry = {
            type: "Point",
            coordinates: center,
          };
          let item = {
            name: dataItem["城市名称"],
            cityLevel: dataItem["城市级别"],
            cityLineLevel: dataItem["城市线级"],
            geom: JSON.stringify(geometry),
            code: adcode,
          };
          this.openLeftItem(item);
        }
      }
    },
    async gotoCityDetail(code) {
      const res = await GetDetailBrowsingRecord({
        module: "cityDetail",
        detailID: code,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("/cityDetail", {
          id: code,
        });
      }
    },
  },
  mounted() {
    this.mapPanel = this.$refs.mapPanel;
    this.cityCode = localStorage.getItem("cityCode");
    let searchKey = this.$route.query.key;
    if (searchKey) {
      this.searchKey = searchKey;
    }
    this.$nextTick(() => {
      this.initData();
    });
  },
  components: {
    FilterBtn,
    MapPanel,
    PagerLittle,
  },
};
</script>

<style></style>
