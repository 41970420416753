export const cityFilters = [
  {
    label: "城市线级",
    key: "cityType",
    name: "城市线级",
    submenu: [
      {
        label: "不限",
        value: "0",
      },
      {
        label: "一线",
        value: "1",
      },
      {
        label: "新一线",
        value: "2",
      },
      {
        label: "二线",
        value: "3",
      },
      {
        label: "三线",
        value: "4",
      },
      {
        label: "四线",
        value: "5",
      },
      {
        label: "五线",
        value: "6",
      },
      {
        label: "其他",
        value: "7",
      },
    ],
  },
];
